export const ROUTES = {
    INTRO_ROUTE: '/',
    MAINPAGE_ROUTE: '/home',
    ABOUT_ROUTE: '/about',
    COMMUNITYENGAGEMENT_ROUTE: '/community-engagement',
    EDUCATION_ROUTE: '/education',
    MEETTEAM_ROUTE: '/meet-the-team',
    SIGNATUREEVENTS_ROUTE: '/signature-events',
    SPECIALEVENTS_ROUTE: '/special-events',
    PHOTOS_ROUTE:'/photos',
    STORE_ROUTE:'http://shop-thehouse.square.site/',
}